<template>
  <section>
    <CreateButton
      v-if="isCreateButtonVisible"
      class="create-button"
      route-name="permissionGroup_new"
    />
    <AbstractDataTable
      :headers="tableHeaders"
      :items="groups"
      :total-count="totalCount"
      :current-page="currentPage"
      :on-page-change="setPageAndGetRecords"
      :show-delete-button="item => !item.hasExternalGroupMapping"
      :button-required-permissions="requiredPermissions"
      action-id-param="groupId"
      store-name="group"
      table-css-class="group-table"
      >
      <template #tableBody="{ item }">
        <TitleColumn
          :title-header="tableHeaders[0].name"
          :title-value="item.groupId"
          subtitle
        />
        <TitleColumn
          :title-header="tableHeaders[1].name"
          :title-value="item.name"
        />
        <AbstractColumn :header="tableHeaders[2].name">
          <i
            class="external-group fas"
            :class="{
              'fa-lock external-group--locked': item.hasExternalGroupMapping,
              'fa-lock-open external-group--unlocked': !item.hasExternalGroupMapping
            }"
          />
        </AbstractColumn>
      </template>
    </AbstractDataTable>
  </section>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import PermissionService from '@/services/PermissionService'
import CreateButton from '@/components/shared/ButtonCreate'
import AbstractColumn from '@/components/table/columns/AbstractColumn'

export default {
  name: 'PermissionGroupListView',
  components: {
    AbstractColumn,
    CreateButton,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    isCreateButtonVisible () {
      const permission = this.requiredPermissions.createButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    groups () {
      return this.$store.getters['group/list']
    },
    totalCount () {
      return this.$store.getters['group/totalCount']
    },
    currentPage () {
      return this.$store.getters['group/page']
    }
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('permissionGroup.list.id') },
        { name: this.$t('permissionGroup.list.name') },
        {
          name: this.$t('permissionGroup.list.external'),
          tooltip: this.$t('permissionGroup.list.external_group_mapping_tooltip')
        },
        { name: this.$t('actions') }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_PERMISSION_GROUP_PERMISSIONS
    }
  },
  methods: {
    getGroups () {
      this.$store.dispatch('group/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('group/setPage', page)
      this.getGroups()
    }
  },
  created () {
    this.$store.commit('group/setPage', 1)
    this.getGroups()
  }
}
</script>
<style lang="scss">
.create-button {
  width: max-content;
  margin-left: auto;
  margin-bottom: 1rem;
}
.group-table {
  &__thead,
  &__tr {
    @include bp(12) {
      grid-template-columns: rem(150px) auto rem(80px) max-content;
    }
    @include bp(14) {
      grid-template-columns: rem(300px) auto rem(80px) rem(138px);
    }
  }
  .external-group {
    padding-left: 1rem;
    &--unlocked {
      color: #5AB963;
    }
    &--locked {
      color: #E9596F;
    }
  }
}
</style>
